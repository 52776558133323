import { createRouter, createWebHistory } from 'vue-router';
import CreateProfile from '@/views/CreateProfile.vue';
import CreateUser from '@/views/CreateUser.vue';
import SignIn from '@/views/SignIn.vue';
import SignUp from '@/views/SignUp.vue';
import PatientDashboard from '@/views/PatientDashboard.vue'
import CreditsHistory from '@/views/CreditsHistory.vue'
import OrderCart from '@/views/OrderCart.vue'


import OrdersDashboard from '@/views/OrdersDashboard.vue'

import PatientProfile from '@/views/PatientProfile.vue'
import EditProfile from '@/views/EditProfile.vue'
import EditUser from '@/views/EditUser.vue'
import CreateAssessment from '@/views/CreateAssessment.vue'
import CreateGuestAssessment from '@/views/CreateGuestAssessment.vue'
import CreateFollowupAssessment from '@/views/CreateFollowupAssessment.vue'
import EditFollowupAssessment from '@/views/EditFollowupAssessment.vue'
import FollowupAssessmentDiagnosisNew from '@/views/FollowupAssessmentDiagnosisNew.vue'
import PreviousAssessment from '@/views/PreviousAssessment.vue'





import EditAssessment from '@/views/EditAssessment.vue'
import AssessmentDiagnosisNew from '@/views/AssessmentDiagnosisNew.vue'

import AssessmentMessages from '@/views/AssessmentMessages.vue'
import UserMessages from '@/views/UserMessages.vue'


import PaymentMethod from '@/views/PaymentMethod.vue'
import FollowupPaymentMethod from '@/views/FollowupPaymentMethod.vue'

import PharmacyPaymentMethod from '@/views/PharmacyPaymentMethod.vue'

import GuestPaymentMethod from '@/views/GuestPaymentMethod.vue'

import WireTransfer from '@/views/WireTransfer.vue'
import PharmacyWireTransfer from '@/views/PharmacyWireTransfer.vue'
import FollowupWireTransfer from '@/views/FollowupWireTransfer.vue'


import CashPayments from '@/views/CashPayments.vue'
import PharmacyCashPayments from '@/views/PharmacyCashPayments.vue'
import FollowupCashPayments from '@/views/FollowupCashPayments.vue'


import TermsConditions from '@/views/TermsConditions.vue'
import PrivacyNotice from '@/views/PrivacyNotice.vue'
import MedicalSpecialists from '@/views/MedicalSpecialists.vue'


//import UserProfile from '@/views/UserProfile.vue';
import ChangePassword from '@/views/ChangePassword.vue';
import PaymentSuccess from '@/views/PaymentSuccess.vue';
import FollowupPaymentSuccess from '@/views/FollowupPaymentSuccess.vue';

import PharmacyPaymentSuccess from '@/views/PharmacyPaymentSuccess.vue';
import DeliveryAddress from '@/views/DeliveryAddress.vue';



import PaymentPlan from '@/views/PaymentPlan.vue';
import PaymentPlans from '@/views/PaymentPlans.vue';
import SubscriptionDetails from '@/views/SubscriptionDetails.vue';
import SubscriptionSuccess from '@/views/SubscriptionSuccess.vue';


import LandingPage from '@/views/LandingPage.vue';






const routes = [

    {
        path: '/signup',
        name: 'SignUp',
        component: SignUp
      },
      {
        path: '/signin',
        name: 'SignIn',
        component: SignIn
      },
      {
        path: '/create-profile',
        name: 'CreateProfile',
        component: CreateProfile,
      },
      {
        path: '/create-user/:email',
        name: 'CreateUser',
        component: CreateUser,
      },
      {
        path: '/patient-dashboard',
        name: 'PatientDashboard',
        component: PatientDashboard,
      },
      {
        path: '/orders-dashboard',
        name: 'OrdersDashboard',
        component: OrdersDashboard,
      },
      {
        path: '/patient-profile/:id',
        name: 'PatientProfile',
        component: PatientProfile,
      },
      {
        path: '/credits-history/:id',
        name: 'CreditsHistory',
        component: CreditsHistory,
      },
      {
        path: '/order-cart/:id',
        name: 'OrderCart',
        component: OrderCart,
      },
      {
        path: '/edit-profile/:id',
        name: 'EditProfile',
        component: EditProfile,
      },
      {
        path: '/edit-user/:id',
        name: 'EditUser',
        component: EditUser,
      },
      {
        path: '/create-assessment',
        name: 'CreateAssessment',
        component: CreateAssessment,
      },
      {
        path: '/consulta',
        name: 'CreateGuestAssessment',
        component: CreateGuestAssessment,
      },
      {
        path: '/create-followup-assessment/:id',
        name: 'CreateFollowupAssessment',
        component: CreateFollowupAssessment,
      },
      {
        path: '/previous-assessment/:id',
        name: 'PreviousAssessment',
        component: PreviousAssessment,
      },
      {
        path: '/subscription-details/:plan',
        name: 'SubscriptionDetails',
        component: SubscriptionDetails,
      },
      {
        path: '/followup-assessment-diagnosis-new/:id',
        name: 'FollowupAssessmentDiagnosisNew',
        component: FollowupAssessmentDiagnosisNew,
      },
      {
        path: '/payment-plan/:id',
        name: 'PaymentPlan',
        component: PaymentPlan,
      },
      {
        path: '/payment-plans/:id',
        name: 'PaymentPlans',
        component: PaymentPlans,
      },
      {
        path: '/edit-followup-assessment/:id',
        name: 'EditFollowupAssessment',
        component: EditFollowupAssessment,
      },
      {
        path: '/edit-assessment/:id',
        name: 'EditAssessment',
        component: EditAssessment,
      },
      {
        path: '/assessment-diagnosis-new/:id',
        name: 'AssessmentDiagnosisNew',
        component: AssessmentDiagnosisNew,
      },
      {
        path: '/delivery-address/:id',
        name: 'DeliveryAddress',
        component: DeliveryAddress,
      },
      {
        path: '/assessment-messages/:id',
        name: 'AssessmentMessages',
        component: AssessmentMessages,
      },
      {
        path: '/user-messages/:id',
        name: 'UserMessages',
        component: UserMessages,
      },
      {
        path: '/payment-method/:id',
        name: 'PaymentMethod',
        component: PaymentMethod,
      },
      {
        path: '/followup-payment-method/:id',
        name: 'FollowupPaymentMethod',
        component: FollowupPaymentMethod,
      },
      {
        path: '/pharmacy-payment-method/:id',
        name: 'PharmacyPaymentMethod',
        component: PharmacyPaymentMethod,
      },
      {
        path: '/guest-payment-method/:id&&:password',
        name: 'GuestPaymentMethod',
        component: GuestPaymentMethod,
      },
      {
        path: '/wire-transfer/:id',
        name: 'WireTransfer',
        component: WireTransfer,
      },
      {
        path: '/followup-wire-transfer/:id',
        name: 'FollowupWireTransfer',
        component: FollowupWireTransfer,
      },
      {
        path: '/pharmacy-wire-transfer/:id',
        name: 'PharmacyWireTransfer',
        component: PharmacyWireTransfer,
      },
      {
        path: '/terminos-y-condiciones',
        name: 'TermsConditions',
        component: TermsConditions,
      },
      {
        path: '/aviso-de-privacidad',
        name: 'PrivacyNotice',
        component: PrivacyNotice,
      },
      {
        path: '/nuestros-especialistas',
        name: 'MedicalSpecialists',
        component: MedicalSpecialists,
      },
      {
        path: "/change-password",
        name: "ChangePassword",
        component: ChangePassword,
      },

      {
        path: '/payment-success',
        name: 'PaymentSuccess',
        component: PaymentSuccess,
      },
      {
        path: '/pharmacy-payment-success',
        name: 'PharmacyPaymentSuccess',
        component: PharmacyPaymentSuccess,
      },
      {
        path: '/followup-payment-success',
        name: 'FollowupPaymentSuccess',
        component: FollowupPaymentSuccess,
      },

      {
        path: '/subscription-success',
        name: 'SubscriptionSuccess',
        component: SubscriptionSuccess,
      },

      {
        path: '/cash-payments/:id',
        name: 'CashPayments',
        component: CashPayments,
      },
      {
        path: '/followup-cash-payments/:id',
        name: 'FollowupCashPayments',
        component: FollowupCashPayments,
      },
      {
        path: '/pharmacy-cash-payments/:id',
        name: 'PharmacyCashPayments',
        component: PharmacyCashPayments,
      },
  {
    path: '/',
    name: 'LandingPage',
    component: LandingPage,
  },
  

  /* {
    path: '/section1training',
    name: 'Section1Training',
    component: Section1Training,
  },

  {
    path: '/section2training',
    name: 'Section2Training',
    component: Section2Training,
  },

  {
    path: '/mockTest',
    name: 'MockTest',
    component: MockTest,
  },

  {
    path: '/scoreReportHistory',
    name: 'scoreReportHistory',
    component: scoreReportHistory,
  },

  {
    path: '/user-metrics',
    name: 'UserMetrics',
    component: UserMetrics,
  },

  {
    path: "/previous-exam/:mock_test_id",
    name: "PreviousExam",
    component: PreviousExam,
  },

  {
    path: "/user-profile",
    name: "UserProfile",
    component: UserProfile,
  },

  {
    path: "/change-password",
    name: "ChangePassword",
    component: ChangePassword,
  },

  

  {
    path: '/payment-plan',
    name: 'PaymentPlan',
    component: PaymentPlan,
  }, */
  
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
  });

export default router;