import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store'; // Import the store
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'bootstrap';



import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyDgaw_hXh2_GMBugL5KD1oQfEyffmB7nG4",
  authDomain: "dermapp-remaster.firebaseapp.com",
  projectId: "dermapp-remaster",
  storageBucket: "dermapp-remaster.appspot.com",
  messagingSenderId: "793458562218",
  appId: "1:793458562218:web:937d5a417238f516fcacd3",
  measurementId: "G-CNH7VC7BPK"
};



const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { app, auth };

const vueApp = createApp(App);

// payment dev
//vueApp.config.globalProperties.$price = 'price_1PvNwoJJ0oNVbEaqMZxtPJJK';
// payment prod
 vueApp.config.globalProperties.$price = 'price_1NaguNJJ0oNVbEaqoCdGNNKm';
 vueApp.config.globalProperties.$followup_price = 'price_1PdHDJJJ0oNVbEaqfbpDalhy';


//subscriptions dev
//vueApp.config.globalProperties.$priceannual = 'price_1PvNyIJJ0oNVbEaqkkl1fPUi';
//vueApp.config.globalProperties.$pricemonthly = 'price_1PvNxLJJ0oNVbEaq1zsG21fe';
//vueApp.config.globalProperties.$pricefamily = 'price_1PvNyjJJ0oNVbEaqvEWlqWg2';

//subscription prod
 vueApp.config.globalProperties.$priceannual = 'price_1PtDSqJJ0oNVbEaqfQkOhz4f';
 vueApp.config.globalProperties.$pricemonthly = 'price_1KYd91JJ0oNVbEaqBDOSA8wJ';
 vueApp.config.globalProperties.$pricefamily = 'price_1NwGOOJJ0oNVbEaqhlOuNNZy';

//loadstripe dev
//vueApp.config.globalProperties.$loadstripepk = 'pk_test_XDTNp2Ay33oaBWSp3hzK6tOI';

//load stripe prod
 vueApp.config.globalProperties.$loadstripepk = 'pk_live_ZoqW5KN9L5o7WoIIdD0CEipo';

//baseurls dev
// vueApp.config.globalProperties.$baseURL = 'http://localhost:3000';
// vueApp.config.globalProperties.$baseURLAdmin = 'http://localhost:8080';
// vueApp.config.globalProperties.$baseURLDerma = 'http://localhost:8082';
// vueApp.config.globalProperties.$baseURLPacientes = 'http://localhost:8081';

//base urls staging
//vueApp.config.globalProperties.$baseURL = 'https://staging-api.dermapp.com';
//vueApp.config.globalProperties.$baseURLPacientes = 'https://staging.dermapp.com';
//vueApp.config.globalProperties.$baseURLDerma = 'https://staging-dermatologos.dermapp.com';
//vueApp.config.globalProperties.$baseURLAdmin = 'https://staging-admin.dermapp.com';

// //base urls prod
 vueApp.config.globalProperties.$baseURL = 'https://api.dermapp.com';
 vueApp.config.globalProperties.$baseURLPacientes = 'https://pacientes.dermapp.com';
 vueApp.config.globalProperties.$baseURLDerma = 'https://dermatologos.dermapp.com';
 vueApp.config.globalProperties.$baseURLAdmin = 'https://admin.dermapp.com';


vueApp.use(router).use(store).mount('#app');