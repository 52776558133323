<template>
    <div class="info-message-wrapper">
      <div class="info-icon-container">
        <img :src="exclamationIcon" alt="Información" class="info-icon" />
      </div>
      <div class="info-message">
        <p>
          {{ message }}
        </p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      exclamationIcon: {
        type: String,
        required: true
      },
      message: {
        type: String,
        required: true
      }
    }
  };
  </script>
  
  <style scoped>
  .info-message-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 40px;
  }
  
  .info-icon-container {
    position: absolute;
    left: -30px; /* Adjust to position the icon outside the message box */
    top: 25%;
    transform: translateY(-50%);
  }
  
  .info-icon {
    width: 30px;
    height: 30px;
  }
  
  .info-message {
    display: flex;
    align-items: center;
    background-color: #F4F6F7;
    border-radius: 7px;
    padding: 10px 15px;
    margin-left: 14px; /* Space to accommodate the icon */
    width: 800px;
    height: 54px;
  }
  
  .info-message p {
    font-family: 'Avenir Medium', sans-serif;
    font-size: 15px;
    color: #000000;
    margin: 0;
    text-align: left;
  }
  
  /* Responsive Styles */
  
  /* Tablets and Small Desktops */
  @media (max-width: 1024px) {
    .info-icon-container {
      left: -25px;
    }
  
    .info-icon {
      width: 30px;
      height: 30px;
    }
  
    .info-message {
      width: 550px;
      height: auto; /* Allow height to adjust based on content */
      padding: 8px 12px;
      margin-left: 12px;
    }
  
    .info-message p {
      font-size: 12px;
    }
  }
  
  /* Mobile Devices and Smaller Tablets */
  @media (max-width: 768px) {
    .info-message-wrapper {
      flex-direction: row;
      align-items: flex-start;
      text-align: left;
    }
  
    .info-icon-container {
      position: relative;
      left: 0;
      top: 0;
      transform: none;
      margin-right: 10px;
    }
  
    .info-icon {
      width: 30px;
      height: 30px;
    }
  
    .info-message {
      width: 100%;
      max-width: 300px;
      height: auto;
      padding: 10px;
      margin-left: 0;
    }
  
    .info-message p {
      font-size: 14px;
    }
  }
  
  /* Extra Small Devices */
  @media (max-width: 480px) {
    .info-icon {
      width: 30px;
      height: 30px;
    }
  
    .info-message {
      max-width: 280px;
      padding: 8px;
    }
  
    .info-message p {
      font-size: 12px;
    }
  }
  </style>