<template>
  <div class="medical-specialists-container">
    <!-- Header -->
    <header
      class="header d-flex align-items-flex-start"
      style="width: 100%; padding-left: 20px; margin-top: -10px;"
    >
      <img :src="HeaderLogo" alt="CreateLogo" class="create-logo" style="height:40px;" />
    </header>

<!-- Title -->
<h2 id="specialists-title" class="text-center my-4 text-white mb-[10px] mt-0" style="font-size: 45px; font-weight: normal;">
  Conoce nuestro equipo de especialistas
</h2>

    
    <!-- Specialists Section -->
    <div class="row justify-content-center">
      <!-- Specialist 1 -->
      <div class="col-md-4 mb-4 d-flex justify-content-center">
        <div class="card specialist-card">
          <img
            :src="require('@/assets/dermatologist_Dulce.png')"
            class="card-img-top specialist-image"
            alt="Dermatologist Dulce"
          />
          <div class="card-body">
            <h5 class="card-title">Dra. Dulce De La Torre</h5>
            <h6 class="card-subtitle mb-2">Médica Cirujana Dermatóloga</h6>
            <p class="card-text"> 
              <span>Médica Cirujana: 3197741</span><br />
              <span>Médica Dermatóloga: 3509343</span>
            </p>
          </div>
        </div>
      </div>

      <!-- Specialist 2 -->
      <div class="col-md-4 mb-4 d-flex justify-content-center">
        <div class="card specialist-card">
          <img
            :src="require('@/assets/dermatologist_Monica.jpeg')"
            class="card-img-top specialist-image"
            alt="Dermatologist Monica"
          />
          <div class="card-body">
            <h5 class="card-title">Dra. Mónica Dávalos Tanaka</h5>
            <h6 class="card-subtitle mb-2">Médica Cirujana Dermatóloga Pediátrica</h6>
            <p class="card-text">
              <span>Médica Cirujana: 8971483</span><br />
              <span>Médica Pediátrica: 11542216</span><br />
              <span>Médica Dermatóloga: 3785407</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MedicalSpecialists',
  data() {
    return {
      HeaderLogo: require('@/assets/dermapp-white.png'), // Ensure this path is correct
    };
  },
};
</script>

<style scoped>
/* Import Comfortaa Font */
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;700&display=swap');


.medical-specialists-container {
  padding: 40px 20px;
  background-color: transparent;
  font-family: 'Comfortaa', cursive;
}

.specialist-card {
  width: 100%;
  max-width: 350px;
  height: 490px;
  margin: 0 auto;
  border-radius: 40px;
  overflow: hidden;
  background-color: #ffffff;
}

.specialist-image {
    height: 65%;
    object-fit: cover;
    object-position: center 27%;
}

.card-body {
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Aligns content to the top */
  align-items: left;
  background-color: #ffffff;
  color: #000000;
  padding: 5px 25px; /* Reduced top padding */
}


.card-title {
  font-weight: normal;
  padding-top: 10px; /* Adjust this value as needed */
  margin-bottom: 5px;
  color: #000000;
  text-align: left;
}


.card-subtitle {
  font-weight: 400;
  font-size: 1rem;
  color: #38B6FF; /* Muted text color */
  margin-bottom: 10px;
  text-align: left;
}

.card-text {
  font-weight: 400;
  color: #000000;
  text-align: left;
}

.card-text span {
  display: inline-block;
}

.card {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s;
  border: none;
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
  
  /* Target the header logo specifically in mobile view */
  .header .create-logo {
    height: 25px !important; /* Further reduce the logo size */
    margin-top: -5px !important; /* Use a negative value to move it closer to the top */
    margin-left: -5px !important; /* Use a negative value to move it closer to the left */
    margin-bottom: 20px;
  }
  
  #specialists-title {
    font-size: 25px !important; /* Adjust this value to make the title smaller */
    margin-top: 5px;
  }

  .specialists-title {
    font-size: 10px; /* Adjust the value as needed */
  }

  .specialist-card {
    max-width: 75%;
    height: auto;
    border-radius: 40px;
  }

  .specialist-image {
    height: 300px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  .card-body {
    height: auto;
    padding: 10px;
  }

  .card-title {
    font-size: 1.2rem;
    text-align: left;
  }

  .card-text {
    font-size: 1rem;
    text-align: left;
  }
}
</style>
