<template>
  <div>
    <!-- Render the banners if available -->
    <div v-if="banners.length > 0" class="banner-container">
      <BannerMessage
        v-for="banner in banners"
        :key="banner.id"
        class="banner-message"
        :backgroundColor="banner.background_color"
        :textColor="banner.text_color"
        :textContent="banner.text_content"
        :iconImageName="banner.icon_image_name"
        :heightDesktop="banner.height_desktop"
        :heightMobile="banner.height_mobile"
      />
    </div>

    <!-- Header -->
    <header class="header d-flex flex-column align-items-start justify-content-between">
      <div 
        class="d-flex align-items-center justify-content-between" 
        style="width: 100%; cursor: pointer;"
        @click="navigateToDashboard"
      >
        <img :src="HeaderLogo" alt="CreateLogo" class="create-logo" style="height:30px;"/>
      </div>
      <nav class="d-flex align-items-center justify-content-between" style="width: 100%; margin-top:20px">
        <button 
          class="regresar-button" 
          style="margin-left:20px;"
          @click="$router.back();">
          <img :src="BackIcon" alt="Back" class="back-icon" style="width:auto; height:20px" />
          Regresar
        </button>
        <button 
          class="nav-item" 
          style="padding-left:10px; padding-right:10px; height:40px; width:auto; margin-right:20px; border: 2px solid white; border-radius: 10px; background: none; color: white; cursor: pointer;" 
          @click="$router.push({ name: 'CreditsHistory', params: { id: userId } })">
          Créditos: {{ credits }}
        </button>
      </nav>
    </header>

    <!-- Main Content -->
    <div class="container">
      <div class="row justify-content-center">
        <div v-if="isDataLoaded" class="col-md-8">
          <!-- Adjusted container to have a fixed height and be scrollable -->
          <div class="card mb-2">
            <div v-if="orders.length > 0">
              <h5 class="text-center mt-4">Mis pedidos</h5>
              <div class="card-body" style="height: 68vh; overflow-y: auto; margin-bottom:60px">
                <!-- Order cards -->
                <div v-for="order in orders" :key="order.id">
                  <div class="card mb-2 d-block text-decoration-none" 
                       style="color: inherit;"
                       v-if="order.status >= 2 && order.status <= 12 && order.status != 11">
                    <div class="card-body">
                      <strong class="card-title">Orden {{ order.id }}</strong>
                      <!-- Order Status Sections -->
                      <div v-if="order.status == 2 && new_message != 2">
                        <img :src="OrderVerified" alt="OrderVerified" class="order-verified" style="height: 50px; width: auto; margin-top:10px; margin-bottom:10px" />
                        <p style="font-size:13px">Hemos recibido el pago de tu pedido.<br>Estamos verificando los datos de envío.</p>
                      </div>
                      <div v-else-if="order.status == 2 && new_message == 2">
                        <img :src="OrderMessages" alt="OrderMessages" class="order-messages" style="height: 50px; width: auto; margin-top:10px; margin-bottom:10px" />
                        <p style="font-size:13px">Tu pedido requiere atención.<br>Nos hemos puesto en contacto contigo para revisar los datos de envío.</p>
                        <router-link :to="{ name: 'UserMessages', params: { id: userId }}" class="btn btn-primary" style="width: 80%; margin-bottom:20px">Mensajes</router-link>
                      </div>
                      <div v-else-if="order.status < 9">
                        <img :src="OrderConfirmed" alt="OrderConfirmed" class="order-confirmed" style="height: 50px; width: auto; margin-top:10px; margin-bottom:10px" />
                        <p style="font-size:13px">Estamos preparando tu pedido.<br>Lo recibirás en 7 a 10 días hábiles.</p>
                        <!-- Progress Bar -->
                        <div style="background-color: #e0e0e0; height: 15px; border-radius: 10px; overflow: hidden; margin-bottom: 10px;">
                          <div
                            :style="{ width: progressPercentage(order) + '%', backgroundColor: '#4caf50', height: '100%' }"
                          ></div>
                        </div>
                        <p style="font-size:13px">{{progressPercentage(order)}}% de tu pedido está listo</p>
                      </div>
                      <div v-else-if="order.status == 9">
                        <img :src="OrderSent" alt="OrderSent" class="order-sent" style="height: 50px; width: auto; margin-top:10px; margin-bottom:10px" />
                        <p style="font-size:13px">Tu pedido ha sido enviado.<br>Aquí tienes tu guía de rastreo:<br></p> 
                        <p v-if="order.tracking_id != 'pendiente'" style="font-size:13px">
                          <a v-if="order.delivery_company == 0"
                             :href="'https://www.dhl.com/mx-es/home/tracking/tracking-global-forwarding.html?submit=1&tracking-id=' + order.tracking_id" 
                             target="_blank" 
                             style="color:blue; text-decoration:underline;">
                            {{order.tracking_id}}
                          </a>
                          <a v-else-if="order.delivery_company == 1"
                             :href="'https://cs.estafeta.com/es/Tracking/searchByGet?wayBillType=1&wayBill=' + order.tracking_id" 
                             target="_blank" 
                             style="color:blue; text-decoration:underline;">
                            {{order.tracking_id}}
                          </a>
                          <a v-else-if="order.delivery_company == 2"
                             :href="'https://www.fedex.com/fedextrack/?trknbr=' + order.tracking_id" 
                             target="_blank" 
                             style="color:blue; text-decoration:underline;">
                            {{order.tracking_id}}
                          </a>
                        </p>
                      </div>
                      <div v-else-if="order.status == 10 && order.is_delivered == 0">
                        <img :src="OrderDelivered" alt="OrderDelivered" class="order-delivered" style="height: 50px; width: auto; margin-top:10px; margin-bottom:10px" />
                        <p style="font-size:13px">Tu pedido ha sido entregado.<br>¿Lo has recibido?</p>
                        <button @click="updateOrderStatus(1, order)" class="btn btn-primary" style="width: 35%; margin-bottom:20px; margin-right:10px">Sí</button>
                        <button @click="updateOrderStatus(2, order)" class="btn btn-primary" style="width: 35%; margin-bottom:20px; margin-left:10px">No</button>
                      </div>
                      <div v-else-if="order.status == 10 && order.is_delivered == 1">
                        <img :src="OrderDelivered" alt="OrderDelivered" class="order-delivered" style="height: 50px; width: auto; margin-top:10px; margin-bottom:10px" />
                        <p style="font-size:13px">Tu pedido ha sido entregado.<br>Gracias por confiar en nosotros para el cuidado de tu piel.</p>
                        <router-link :to="{ name: 'AssessmentDiagnosisNew', params: { id: order.assessment_id }}" class="btn btn-primary" style="width: 80%; margin-bottom:20px">Volver a comprar</router-link>
                      </div>
                      <div v-else-if="order.status == 12 && order.is_delivered != 2">
                        <img :src="OrderIssue" alt="OrderIssue" class="order-issue" style="height: 50px; width: auto; margin-top:10px; margin-bottom:10px" />
                        <p style="font-size:13px">Tu pedido está detenido.<br>Contacta a DHL para reanudarlo.<br></p> 
                        <p v-if="order.tracking_id != 'pendiente'" style="font-size:13px">
                          <a 
                            :href="'https://www.dhl.com/mx-es/home/tracking/tracking-global-forwarding.html?submit=1&tracking-id=' + order.tracking_id" 
                            target="_blank" 
                            style="color:blue; text-decoration:underline;">
                            {{order.tracking_id}}
                          </a>
                        </p>
                      </div>
                      <div v-else-if="order.status == 12 && order.is_delivered == 2">
                        <img :src="OrderIssue" alt="OrderIssue" class="order-issue" style="height: 50px; width: auto; margin-top:10px; margin-bottom:10px" />
                        <p style="font-size:13px">Parece que hubo un error en la entrega.<br>Contacta a soporte para solucionarlo.<br></p> 
                        <router-link :to="{ name: 'UserMessages', params: { id: userId }}" class="btn btn-primary" style="width: 80%; margin-bottom:20px">Mensajes</router-link>
                      </div>
                      <div class="stepper mb-2" style="width:80%; margin-left:10%">
                        <div class="step-container">
                          <span class="step" :class="{ active: order.status >= 2 }"></span>
                          <label class="step-label">Pedido<br>Pagado</label>
                        </div>
                        <span class="step-line" :class="{ active: order.status >= 3 }"></span>
                        <div class="step-container">
                          <span class="step" :class="{ active: order.status >= 3 }"></span>
                          <label class="step-label">Pedido<br>Confirmado</label>
                        </div>
                        <div class="step-line" :class="{ active: order.status >= 9 }"></div>
                        <div class="step-container">
                          <span class="step" :class="{ active: order.status >= 9 }"></span>
                          <label class="step-label">Pedido<br>Enviado</label>
                        </div>
                        <div class="step-line" :class="{ active: order.status > 9 }"></div>
                        <div class="step-container">
                          <span class="step" :class="{ active: order.status > 9 }"></span>
                          <label class="step-label">Pedido<br>Entregado</label>
                        </div>
                      </div>
                    </div>
                    <div class="text-center">
                      <button @click="toCart(order)" class="btn-blue">Volver a Comprar</button>
                    </div>
                    <div class="text-center">
                      <button @click="showPurchase(order)" class="btn-transparent">Ver Recibo</button>
                    </div>              
                  </div>
                </div> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { mapActions, mapState } from 'vuex';
import axios from 'axios';
import useFacebookPixel from '@/composables/useFacebookPixel';
import { onMounted, ref, createApp, h} from 'vue';
import Swal from 'sweetalert2';
import BannerMessage from '@/components/BannerMessage.vue'; // Import BannerMessage

export default {
  name: 'OrdersDashboard',
  components: { BannerMessage }, // Register BannerMessage component
  setup() {
    const { track } = useFacebookPixel();
    const banners = ref([]);
    const location = 'orders_dashboard'; // Define the location for banner fetching

    const fetchBannerSettings = async () => {
      try {
        const response = await fetch(`https://api.dermapp.com/api/banner-settings?show_banner=1&location=${location}`);
        if (response.ok) {
          const data = await response.json();
          banners.value = data; // Assign all fetched banners
        } else {
          banners.value = [];
        }
      } catch (error) {
        console.error('Error fetching banners:', error);
        banners.value = [];
      }
    };

    onMounted(() => {
      track('ViewContent', { content_name: 'Patient dashboard' });
      fetchBannerSettings();
    });

    return {
      banners,
    };
  },
  data() {
    return {
      profiles: [],
      orders: [],
      isDataLoaded: false,
      HeaderLogo: require('@/assets/dermapp-white.png'),
      User: require('@/assets/edit-user-icon.png'),
      AddProfile: require('@/assets/add-profile-icon.png'),
      Messages: require('@/assets/message-icon.png'),
      NewMessages: require('@/assets/new-message-icon.png'),
      OrderConfirmed: require('@/assets/order-confirmed.webp'),
      OrderDelivered: require('@/assets/order-delivered.webp'),
      OrderSent: require('@/assets/order-sent.webp'),
      OrderMessages: require('@/assets/order-messages.webp'),
      OrderVerified: require('@/assets/order-verified.webp'),
      OrderIssue: require('@/assets/order-issue.webp'),
      BackIcon: require('@/assets/arrow-back.png'),
      new_message: null,
    };
  },
  computed: {
    ...mapState([
      'email', 
      'userId', 
      'isSubscribed', 
      'credits', 
      'country', 
      'phone', 
      'referralCode', 
      'profileId', 
      'profileSex', 
      'profileName', 
      'primaryName', 
      'primaryBirthday'
    ]),
  },
  mounted() {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          // Refresh the token
          const idToken = await user.getIdToken(true); // 'true' forces token refresh

          // Update localStorage with the new token
          localStorage.setItem('Token', idToken);
          localStorage.setItem('Email', user.email);
          const token = localStorage.getItem('Token');

          // Fetch the orders data
          const orders_response = await axios.get(`${this.$baseURL}/api/user-orders`, {
            params: {
              user_id: this.userId,
            },
            headers: {
              'Authorization': `Bearer ${token}`
            },
          });
          this.orders = orders_response.data.orders;
          this.isDataLoaded = true;
        } catch (error) {
          console.error('Error al renovar el token:', error);

          // If the token is expired or there's an authentication error
          if (error.code === 'auth/id-token-expired' || 
              error.code === 'auth/user-token-expired' || 
              error.message.includes('Token')) {
            localStorage.removeItem('Token'); // Remove expired token
            this.$router.push('/SignIn'); // Redirect to login
          }
        }
      } else {
        // If no authenticated user, redirect to login
        this.$router.push('/SignIn'); // Redirect to login
      }
    });
  },
  methods: {
    navigateToDashboard() {
      if (this.email) {
        this.$router.push({ 
          path: '/patient-dashboard', 
          query: { email: this.email } 
        }).catch(err => {
          // Handle the error if navigation fails (optional)
          if (err.name !== 'NavigationDuplicated') {
            console.error(err);
          }
        });
      } else {
        // Handle the case where email is not available
        console.warn('Email is not defined.');
      }
    },

    async toCart(order){
      let checkout = {
        products: order.products,
        total: order.total,
        assessment_id: order.assessment_id,
      }
      try {
        const response = await axios.post(`${this.$baseURL}/api/create-pharmacy-order`, checkout);
        if (response.status === 200) {
          this.$router.push(`/order-cart/${response.data.order_id[0].order_id}`);
        } else {
          console.error('Error creating order:', response);
        }
      } catch (error) {
        console.error('Error in toCart:', error);
      }
    },

    async showPurchase(order) {
      const { default: QuoteReceipt } = await import('./QuoteReceipt.vue');

      Swal.fire({
        html: '<div id="quote-plan-container" style="overflow:hidden;"></div>',
        width: '100%',
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        showDenyButton: false,
        showConfirmButton: false,

        denyButtonText: 'Regresar',
        denyButtonColor: '#0d6efd',
        confirmButtonText: 'Continuar',
        confirmButtonColor: '#0d6efd',

        backdrop: 'rgba(54, 181, 255, 1)', // Customized backdrop color

        didOpen: () => {
          const confirmButton = document.querySelector('.swal2-confirm');
          const denyButton = document.querySelector('.swal2-deny');
          if (confirmButton && denyButton) {
            confirmButton.parentNode.insertBefore(denyButton, confirmButton);
          }

          const app = createApp({
            render: () =>
              h(QuoteReceipt, {
                treatment_plan: JSON.parse(order.products),
                assessmentId: order.assessment_id,
                orderId: order.id,
                orderTotal: String(order.total.toFixed(2)),
                orderPaymentMethod: order.payment_method,
                orderPaidAt: order.paid_at,
                token: localStorage.getItem('Token'),
                onUpdateTreatmentPlan: (newPlan) => {
                  order.products = newPlan;
                },
              }),
          });
          app.mount('#quote-plan-container');
        },
      });
    },

    async updateOrderStatus(mode, order) {
      let order_data = {
        order_id: order.id,
        status: order.status,
        order_uuid: order.order_uuid,
        sap_id: order.sap_id,
        invoice_id: order.invoice_id,
        tracking_id: order.tracking_id,
        prescription_id: order.prescription_id,
        phone: order.phone,
        email: order.email,
        patient_name: order.name,
        is_delivered: order.is_delivered,
      }

      if(mode == 0){
        order_data.status = 12;
        order_data.is_delivered = 2;
      } else {
        order_data.is_delivered = 1;
      }

      try {
        await axios.put(`${this.$baseURL}/api/update-order-status`, order_data);
        order.status = order_data.status;
        order.is_delivered = order_data.is_delivered;
      } catch (error) {
        console.error('Error updating order status:', error);
      }
    },

    progressPercentage(order) {
      // Parse the SAP ID array
      const sapArray = JSON.parse(order.sap_id);
      // Calculate total steps
      const totalSteps = sapArray.filter(item => item.split(" ").length === 2).length;
      // Calculate percentage
      const percentage = sapArray.length > 0 ? (100 / sapArray.length) * totalSteps : 0;
      return percentage.toFixed(0);
    },

    ...mapActions([
      'updateUserId', 
      'updateEmail', 
      'updatePhone', 
      'updateCountry', 
      'updateCredits', 
      'updateReferralCode', 
      'updateProfileId', 
      'updateProfileName', 
      'updateProfileSex', 
      'updateProfileSubscription'
    ]),

    calculateAge(birthday) {
      const birthdate = new Date(birthday);
      const ageDifMs = Date.now() - birthdate.getTime();
      const ageDate = new Date(ageDifMs); // milliseconds from epoch
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    },

    parseSex(sex){
      return sex === 'Male' ? 'Masculino' : 'Femenina';
    },
    
    async getProfiles() {
      try {
        const response = await axios.get(`${this.$baseURL}/api/get-profiles`, {
          params: {
            user_id: this.userId,
          },
        });

        this.profiles = response.data.data;

      } catch (error) {
        console.error('Error fetching profiles:', error);
      }
    },

    addNewProfile() {
      this.$router.push({ name: 'CreateProfile' });
    },
  },
};
</script>



<style scoped>
/* Ensure the root div has no margin or padding */
#app {
  margin-top: 0 !important;
  padding: 0;
  width: 100%;
  height: 100%;
}

/* Banner Styles */
.container {
  padding-top: 0; /* Remove top padding */
  padding-left: 20px; /* Optional: Add side padding */
  padding-right: 20px; /* Optional: Add side padding */
  padding-bottom: 20px; /* Optional: Add bottom padding */
}

/* In your component's <style scoped> */
.banner-container {
  width: 100%;
  margin-top: -10px; /* Remove any default margins */
  padding: 0; /* Remove any default paddings */
}

.banner-message {
  width: 100%;
  margin: 0; /* Ensure no margins around individual banners */
  padding: 0; /* Ensure no padding */
}

.header {
  width: 100%;
  padding: 10px 20px; /* Reduced padding for tighter alignment */
  margin: 0; /* Remove any default margins */
  background: #36b5ff;
}


/* Existing Styles... */

.regresar-button {
  padding-left: 10px;
  padding-right: 10px;
  height: 40px;
  width: auto;
  margin-right: 20px; /* Ajusta según sea necesario */
  border: 2px solid #0D80FF;
  border-radius: 10px;
  background-color: #0D80FF; /* Color de fondo azul */
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Transición suave opcional */
}

.regresar-button:hover {
  background-color: #0B6CD8; /* Tono más oscuro al pasar el cursor */
}

/* Import Comfortaa font */
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;700&display=swap');

/* New button style */
.btn-transparent {
  background: transparent;
  border: 2px solid black;
  color: black;
  border-radius: 15px;
  font-family: 'Comfortaa', cursive;
  cursor: pointer;
  width: 80%;
  margin-bottom: 20px;
  padding: 10px 0; /* Adjust padding as needed */
  text-align: center;
  transition: background-color 0.3s, color 0.3s;
}

.btn-transparent:hover {
  background-color: black;
  color: white;
}

/* Optional: Remove default button outline on focus */
.btn-transparent:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2);
}

/* New button style */
.btn-blue {
  background: blue;
  border: 2px solid blue;
  color: white;
  border-radius: 15px;
  font-family: 'Comfortaa', cursive;
  cursor: pointer;
  width: 80%;
  margin-bottom: 20px;
  padding: 10px 0; /* Adjust padding as needed */
  text-align: center;
}

.nav-item {
  margin-right: 20px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.stepper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.step {
  width: 20px;
  height: 20px;
  margin: 0 0px;
  border-radius: 50%;
  background-color: #E0E0E0; /* gray color for inactive steps */
  border-color:#E0E0E0;
  display: inline-block;
  font-size: 14px;           /* font size for the step number */
  color: #777;               /* font color for inactive steps */
  line-height: 20px;         /* centers the number vertically */
  text-align: center;        /* centers the number horizontally */
  z-index:1;
}

.step.active {
  background-color: #36b5ff; /* blue color for the active step */
  color: #fff;               /* font color for the active step */
  border-color:#36b5ff;
}

.step-label {
  margin-top: 5px;
  font-size: 10px;
}

.step-line {
  flex-grow: 1;
  height: 4px;
  margin-bottom:35px;
  margin-left:-20px;
  margin-right:-20px;
  background-color: #E0E0E0;
  border-color:#E0E0E0;
}

.step-line.active {
  background-color: #36b5ff; /* blue color for the active step */
  color: #fff;               /* font color for the active step */
  border-color:#36b5ff;
}

.step-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Adjustments for responsive design */
@media (max-width: 768px) {
  .container {
    padding-top: 20px; /* Adjust if necessary */
  }
  
}
</style>

