<template>
  <div class="banner" :style="bannerStyle">
    <!-- Conditionally render the icon background -->
    <div v-if="hasIcon" class="icon-background"></div>
    
    <!-- Conditionally render the icon image -->
    <img v-if="hasIcon && iconImageSrc" :src="iconImageSrc" alt="Banner Icon" class="banner-icon" />
    
    <!-- Container for scrolling text -->
    <div class="banner-text-container" :style="textContainerStyle">
      <!-- First set of texts -->
      <div class="banner-text-set">
        <span class="banner-text" v-for="(text, index) in repeatedTextArray" :key="index">
          {{ text }}
        </span>
      </div>
      
      <!-- Duplicate set of texts for seamless scrolling -->
      <div class="banner-text-set">
        <span class="banner-text" v-for="(text, index) in repeatedTextArray" :key="'dup-' + index">
          {{ text }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BannerMessage',
  props: {
    backgroundColor: {
      type: String,
      default: '#0000FF',
    },
    textColor: {
      type: String,
      default: '#FFFFFF',
    },
    textContent: {
      type: String,
      default: 'Default text',
    },
    iconImageName: {
      type: String,
      default: '', // Default to empty string
    },
    heightDesktop: {
      type: String,
      default: '45px',
    },
    heightMobile: {
      type: String,
      default: '40px',
    },
  },
  data() {
    return {
      isMobile: window.innerWidth <= 768,
    };
  },
  computed: {
    bannerStyle() {
      return {
        backgroundColor: this.backgroundColor,
        color: this.textColor,
        height: this.isMobile ? this.heightMobile : this.heightDesktop,
      };
    },
    textArray() {
      return this.textContent.split('|').filter(text => text.trim() !== '');
    },
    repeatedTextArray() {
      if (!this.isMobile) {
        // Repeat the text array multiple times for desktop view
        return [...this.textArray, ...this.textArray, ...this.textArray];
      }
      return this.textArray;
    },
    hasIcon() {
      return this.iconImageName && this.iconImageName.trim() !== '';
    },
    iconImageSrc() {
      if (this.hasIcon) {
        try {
          // Use require.context to dynamically load images
          const images = require.context('@/assets/', false, /\.(png|jpe?g|svg)$/);
          return images(`./${this.iconImageName}`);
        } catch (e) {
          console.error('Icon image not found:', this.iconImageName);
          return null;
        }
      }
      return null;
    },
    textContainerStyle() {
      return {
        paddingLeft: this.hasIcon ? '70px' : '20px', // Adjust padding if no icon
      };
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth <= 768;
    },
  },
};
</script>

<style scoped>
.banner {
  position: relative;
  display: flex;
  align-items: center;
  background-color: inherit;
  height: inherit;
  padding: 0 20px;
  color: inherit;
  box-sizing: border-box;
  margin: 0;
  overflow: hidden;
}

.icon-background {
  position: absolute;
  left: 0px;
  top: 2.5px;
  height: 40px;
  width: 60px;
  background-color: inherit;
  z-index: 1;
}

.banner-icon {
  position: absolute;
  left: 20px;
  height: 40px;
  width: 40px;
  z-index: 2;
  flex-shrink: 0;
}

.banner-text-container {
  display: flex;
  white-space: nowrap;
  animation: scroll-text 40s linear infinite;
  animation-delay: 2s;
  z-index: 0;
}

.banner-text-set {
  display: flex;
}

.banner-text {
  font-family: 'Avenir Medium', sans-serif;
  font-size: 15px;
  margin-right: 50px;
}

@keyframes scroll-text {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .banner {
    height: 40px;
    padding: 0 10px;
  }

  .icon-background {
    left: 0px;
    top: 0px;
  }

  .banner-icon {
    height: 40px;
    width: 40px;
    left: 10px;
  }

  .banner-text {
    font-size: 16px;
    margin-right: 40px;
  }

  .banner-text-container {
    animation: scroll-text 20s linear infinite;
    animation-delay: 3s;
    z-index: 0;
  }
}
</style>
